import { useCallback, useEffect } from 'react';

import { LOCALSTORE_AUTH_TOKENS_KEY } from 'config/auth';
import { paths } from 'config/paths';
import { useNavigate } from 'react-router-dom';

const useAuthTokensListener = () => {
  const navigate = useNavigate();

  const handleStorageChange = useCallback(
    (e: StorageEvent) => {
      if (e.key === LOCALSTORE_AUTH_TOKENS_KEY && e.newValue === null) {
        navigate(paths.LOGOUT);
      } else if (
        e.newValue &&
        e.key === LOCALSTORE_AUTH_TOKENS_KEY &&
        e.newValue !== localStorage.getItem(LOCALSTORE_AUTH_TOKENS_KEY)
      ) {
        localStorage.setItem(LOCALSTORE_AUTH_TOKENS_KEY, e.newValue);
      }
    },
    [navigate],
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [handleStorageChange]);
};

export default useAuthTokensListener;
