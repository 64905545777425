import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledLoader = styled.div`
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  width: 43px;
  height: 43px;
  border-radius: 50%;
  background: radial-gradient(
        farthest-side,
        ${(props) => props.theme.colors.navy} 94%,
        ${(props) => props.theme.colors.transparent}
      )
      top/9px 9px no-repeat,
    conic-gradient(
      ${(props) => props.theme.colors.transparent} 30%,
      ${(props) => props.theme.colors.navy}
    );
  mask: radial-gradient(
    farthest-side,
    ${(props) => props.theme.colors.transparent} calc(100% - 9px),
    ${(props) => props.theme.colors.black} 0
  );
  animation: animate 1s linear infinite;
`;
