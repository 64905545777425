import React, { ReactNode, useContext, useState } from 'react';

import { Alert as AlertComponent } from 'components/Alert/Alert';
import ErrorBlock from 'components/ErrorBlock/ErrorBlock';
import Modal from 'components/Modal/Modal';

type Alert = {
  type: 'error' | 'success' | 'logout';
  content: ReactNode;
  onButtonClick?: () => void;
  buttonLabel?: ReactNode;
};

interface GlobalAlertContextType {
  alert: Alert | null;
  setAlert: (alert: Alert | null) => void;
}

export const GlobalAlertContext = React.createContext<GlobalAlertContextType | null>(null);

export const GlobalAlertContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [alert, setAlert] = useState<Alert | null>(null);

  const handleCloseAlert = () => {
    setAlert(null);
  };

  return (
    <GlobalAlertContext.Provider value={{ alert, setAlert }}>
      <Modal isOpen={alert?.type === 'error'} handleClose={handleCloseAlert}>
        <ErrorBlock
          content={alert?.content}
          actionButton={
            alert?.onButtonClick
              ? {
                  onClick: () => {
                    handleCloseAlert();
                    alert?.onButtonClick && alert.onButtonClick();
                  },
                  customLabel: alert.buttonLabel,
                }
              : undefined
          }
        />
      </Modal>
      {(alert?.type === 'logout' || alert?.type === 'success') && (
        <AlertComponent
          type={alert.type}
          statement={alert.content}
          handleClick={() => {
            handleCloseAlert();
            alert.onButtonClick && alert.onButtonClick();
          }}
          customButtonLabel={alert.buttonLabel}
        />
      )}
      {children}
    </GlobalAlertContext.Provider>
  );
};

const useGlobalAlert = () => {
  const globalAlertContext = useContext(GlobalAlertContext);

  if (globalAlertContext === null) {
    throw Error('Context has not been Provided!');
  }

  const showGlobalAlert = (alert: Alert) => {
    globalAlertContext.setAlert(alert);
  };

  return {
    showGlobalAlert,
  };
};

export default useGlobalAlert;
