import { ReactNode } from 'react';

import { Button } from 'components/Button/Button';
import { ReactComponent as InfoIcon } from 'icons/infoIcon.svg';
import { Link } from 'react-router-dom';

import { InfoBlockWrapper, IconWrapper, Message } from './InfoBlock.styles';

export type InfoBlockProps = {
  content: ReactNode;
  actionButton?:
    | {
        onClick: () => void;
        label: ReactNode;
      }
    | {
        to: string;
        label: ReactNode;
      };
};

const InfoBlock = ({ content, actionButton }: InfoBlockProps) => {
  return (
    <InfoBlockWrapper>
      <IconWrapper>
        <InfoIcon />
      </IconWrapper>
      <Message>{content}</Message>
      {actionButton && 'onClick' in actionButton && (
        <div>
          <Button type="button" size="medium" variant="default" onClick={actionButton.onClick}>
            {actionButton.label}
          </Button>
        </div>
      )}
      {actionButton && 'to' in actionButton && (
        <div>
          <Button type="button" size="medium" variant="default" as={Link} to={actionButton.to}>
            {actionButton.label}
          </Button>
        </div>
      )}
    </InfoBlockWrapper>
  );
};

export default InfoBlock;
