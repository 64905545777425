import {
  getAuthTokensFromStore,
  KEYCLOAK_CONFIG,
  removeAuthTokens,
  saveAuthTokens,
} from 'config/auth';
import { paths } from 'config/paths';
import Keycloak from 'keycloak-js';

class KeycloakManager {
  keycloakInstance: Keycloak.KeycloakInstance;

  constructor() {
    this.keycloakInstance = Keycloak(KEYCLOAK_CONFIG);

    this.keycloakInstance.onAuthSuccess = function () {
      if (this.token && this.refreshToken) {
        saveAuthTokens(this.token, this.refreshToken);
      }
    };
    this.keycloakInstance.onAuthRefreshSuccess = function () {
      if (this.token && this.refreshToken) {
        saveAuthTokens(this.token, this.refreshToken);
      }
    };
    this.keycloakInstance.onAuthLogout = function () {
      removeAuthTokens();
    };
  }

  async init(callback: () => void) {
    try {
      const tokens = getAuthTokensFromStore();
      await this.keycloakInstance.init({
        checkLoginIframe: false,
        token: tokens?.accessToken || undefined,
        refreshToken: tokens?.refreshToken || undefined,
        onLoad: 'check-sso',
      });
      callback();
    } catch (error) {
      callback();
    }
  }

  getLoginUrl = () => {
    return this.keycloakInstance.createLoginUrl({
      redirectUri: window.location.origin + paths.LOGIN_CALLBACK,
    });
  };

  getLogoutRelative = (
    withCallbackSuccessModal?: boolean,
    withCallbackRoleModal?: boolean,
  ): string => {
    if (withCallbackSuccessModal) {
      return paths.LOGOUT_CALLBACK_WITH_SUCCESS_MODAL;
    }
    if (withCallbackRoleModal) {
      return paths.LOGOUT_CALLBACK_WITH_ROLE_MODAL;
    }
    return paths.LOGOUT_CALLBACK;
  };

  getLogoutUrl = (withCallbackSuccessModal?: boolean, withCallbackRoleModal?: boolean) => {
    const pathRelative = this.getLogoutRelative(withCallbackSuccessModal, withCallbackRoleModal);
    return this.keycloakInstance.createLogoutUrl({
      redirectUri: window.location.origin + pathRelative,
    });
  };

  getRegisterUrl = () => {
    return KEYCLOAK_CONFIG.registerUrl;
  };

  getAccessToken = () => {
    return this.keycloakInstance.token;
  };

  updateToken = (minValidity = 30) => {
    return this.keycloakInstance.updateToken(minValidity);
  };

  getIsAuthenticated = () => {
    return this.keycloakInstance.authenticated;
  };
}

export default new KeycloakManager();
