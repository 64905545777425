import React from 'react';

import Loader from 'components/Loader/Loader';
import { paths } from 'config/paths';
import Home from 'features/core/views/Home/Home';
import ReactGA from 'react-ga4';
import { Route, Routes } from 'react-router-dom';
import useAuthTokensListener from 'utils/useAuthTokensListener';

const AuthRouteContainer = React.lazy(
  () => import(/* webpackPrefetch: true */ 'containers/AuthRouteContainer/AuthRouteContainer'),
);
const CoreCurriculum = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/calendar/views/CoreCurriculum/CoreCurriculum'),
);
const DidacticMaterials = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ 'features/calendar/views/DidacticMaterials/DidacticMaterials'
    ),
);
const Onboarding = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/calendar/views/Onboarding/Onboarding'),
);
const UnrealizedTopics = React.lazy(
  () =>
    import(/* webpackPrefetch: true */ 'features/calendar/views/UnrealizedTopics/UnrealizedTopics'),
);
const LoginCallbackRedirect = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ 'features/core/views/LoginCallbackRedirect/LoginCallbackRedirect'
    ),
);
const Library = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/library/views/Library/Library'),
);
const LibraryNew = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/library/views/LibraryNew/LibraryNew'),
);
const AboutMe = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/myAccount/views/AboutMe/AboutMe'),
);
const AddClass = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/myAccount/views/AddClass/AddClass'),
);
const AddSubject = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/myAccount/views/AddSubject/AddSubject'),
);
const LegalInformation = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ 'features/myAccount/views/LegalInformation/LegalInformation'
    ),
);
const MyAccount = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/myAccount/views/MyAccount/MyAccount'),
);
const MyGroups = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/myAccount/views/MyGroups/MyGroups'),
);
const Tutorial = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/myAccount/views/Tutorial/Tutorial'),
);
const TechnicalSupport = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ 'features/myAccount/views/TechnicalSupport/TechnicalSupport'
    ),
);
const AddReminder = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/notifications/views/AddReminder/AddReminder'),
);
const EditReminder = React.lazy(
  () =>
    import(/* webpackPrefetch: true */ 'features/notifications/views/EditReminder/EditReminder'),
);
const Notifications = React.lazy(
  () =>
    import(/* webpackPrefetch: true */ 'features/notifications/views/Notifications/Notifications'),
);
const CurrentTopics = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/calendar/views/CurrentTopics/CurrentTopics'),
);
const LogoutCallbackRedirect = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ 'features/core/views/LogoutCallbackRedirect/LogoutCallbackRedirect'
    ),
);
const Page404 = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/core/views/Page404/Page404'),
);
const Logout = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/core/views/Logout/Logout'),
);
const LogoutCallbackModal = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ 'features/core/views/LogoutCallbackModal/LogoutCallbackModal'
    ),
);
const LogoutRoleModal = React.lazy(
  () => import(/* webpackPrefetch: true */ 'features/core/views/LogoutRoleModal/LogoutRoleModal'),
);

function App() {
  useAuthTokensListener();

  const gaId = process.env.REACT_APP_GA_ID;
  if (gaId) ReactGA.initialize(gaId);

  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<AuthRouteContainer />}>
          <Route path={paths.CALENDAR_ONBOARDING} element={<Onboarding />} />
          <Route path={paths.CALENDAR_CURRENT_TOPICS} element={<CurrentTopics />} />
          <Route path={paths.CALENDAR_UNREALIZED_TOPICS} element={<UnrealizedTopics />} />
          <Route path={paths.CALENDAR_CORE_CURRICULUM} element={<CoreCurriculum />} />
          <Route path={paths.MATERIALS} element={<DidacticMaterials />} />
          <Route path={paths.LIBRARY} element={<Library />} />
          <Route path={paths.LIBRARY_NEW} element={<LibraryNew />} />
          <Route path={paths.REMINDER_EDIT(':id')} element={<EditReminder />} />
          <Route path={paths.REMINDER_NEW} element={<AddReminder />} />
          <Route path={paths.NOTIFICATIONS} element={<Notifications />} />
          <Route path={paths.ADD_CLASS_SCHOOL_YEAR_ID(':schoolYearId')} element={<AddClass />} />
          <Route path={paths.ADD_CLASS} element={<AddClass />} />
          <Route path={paths.ADD_SUBJECT(':groupId')} element={<AddSubject />} />
          <Route path={paths.MY_ACCOUNT} element={<MyAccount />} />
          <Route path={paths.MY_GROUPS} element={<MyGroups />} />
          <Route path={paths.ABOUT_ME} element={<AboutMe />} />
          <Route path={paths.TUTORIAL} element={<Tutorial />} />
          <Route path={paths.TECHNICAL_SUPPORT} element={<TechnicalSupport />} />
          <Route path={paths.LEGAL_INFO} element={<LegalInformation />} />
          <Route path={paths.LOGIN_CALLBACK} element={<LoginCallbackRedirect />} />;
        </Route>
        <Route path={paths.LOGOUT_CALLBACK_WITH_SUCCESS_MODAL} element={<LogoutCallbackModal />} />
        <Route path={paths.LOGOUT_CALLBACK_WITH_ROLE_MODAL} element={<LogoutRoleModal />} />
        <Route path={paths.LOGOUT_CALLBACK} element={<LogoutCallbackRedirect />} />;
        <Route path={paths.LOGOUT} element={<Logout />} />;
        <Route path={paths.HOME} element={<Home />} />;
        <Route path="*" element={<Page404 />} />
      </Routes>
    </React.Suspense>
  );
}

export default App;
