import styled from 'styled-components';
import { respondTo } from 'styles/utils/respondTo';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  position: fixed;
  inset: 0;
  box-shadow: ${(props) => props.theme.boxShadows.dark};
  background: linear-gradient(${(props) => props.theme.gradient.green});
  color: ${(props) => props.theme.colors.green140};

  ${respondTo.m`
    position: static;

    width: 100vw;
    max-width: 800px;

    height: 100vh;
    max-height: 700px;
  `}
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  line-height: 46px;
  font-size: ${(props) => props.theme.fontSizes.xxlarge};
  font-weight: ${(props) => props.theme.fontWeight.extrabold};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 10px;
  text-align: center;
`;

export const TextStatement = styled.div`
  text-align: center;
  line-height: 26px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSizes.large};
`;

export const IconWrapper = styled.div`
  fill: ${(props) => props.theme.colors.green};

  rect {
    fill: ${(props) => props.theme.colors.white};
  }
`;
