import styled, { css } from 'styled-components';
import { respondTo } from 'styles/utils/respondTo';

import { Position } from './Modal';

export const ModalStyle = styled.div<{
  hasPadding: boolean;
  position?: Position;
  limitWidth: boolean;
  radius?: string;
}>`
  min-width: 350px;
  max-width: ${({ limitWidth }) => (limitWidth ? '350px' : '100vw')};
  margin: 0 auto;
  border: 0 !important;
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.boxShadows.dark};
  border-radius: ${(props) => props.theme.radius.medium} !important;
  padding: 0 !important;
  inset: unset !important;
  max-height: 100vh;
  box-sizing: border-box;

  ${({ hasPadding }) =>
    hasPadding &&
    css`
      padding: 20px !important;
    `};

  ${({ position, limitWidth }) => respondTo.m`
    max-width: ${limitWidth ? '375px' : '100vw'};

  ${
    position &&
    css`
      left: ${position.x} !important;
      top: ${position.y} !important;
      max-width: unset;
    `
  }
  `}

  ${(props) => respondTo.m`
      border-radius: ${
        props.radius ? `${props.radius} !important` : `${props.theme.radius.medium} !important`
      };
  `}
`;

export const OverlayStyle = styled.div`
  background-color: ${(props) => props.theme.colors.overlay} !important;
  position: fixed;
  inset: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.button`
  padding: 0;
  outline: 0;
  border: 0;
  background: none;
  cursor: pointer;
`;

export const ModalFooter = styled.div`
  padding: 20px;
  margin: 20px -20px -20px -20px;
  background: ${(props) => props.theme.colors.gray130};
`;
