import { FC, ReactNode } from 'react';

import { Button } from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import { ReactComponent as LogoutIcon } from 'icons/logoutIcon.svg';
import { ReactComponent as SuccessIcon } from 'icons/successIcon.svg';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { TextWrapper, Wrapper, HeaderWrapper, TextStatement, IconWrapper } from './Alert.styles';

export type AlertType = 'logout' | 'success';

interface AlertProps {
  statement?: ReactNode;
  type: AlertType;
  handleClick: () => void;
  customButtonLabel?: ReactNode;
}

export const Alert: FC<AlertProps> = ({ statement, type, handleClick, customButtonLabel }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const modalRadius = theme.radius.large;
  let content;

  if (type === 'success') {
    content = (
      <>
        <HeaderWrapper>
          <SuccessIcon />
          <Trans>{t('alert.success')}</Trans>
        </HeaderWrapper>
        <TextStatement>{statement}</TextStatement>
      </>
    );
  } else if (type === 'logout') {
    content = (
      <HeaderWrapper>
        <IconWrapper>
          <LogoutIcon />
        </IconWrapper>
        <Trans>{t('alert.logout')}</Trans>
      </HeaderWrapper>
    );
  }

  return (
    <Modal
      isOpen
      handleClose={() => {}}
      isCloseButtonHidden={true}
      hasPadding={false}
      radius={modalRadius}
    >
      <Wrapper>
        <TextWrapper>{content}</TextWrapper>
        <Button size="medium" variant="green" onClick={handleClick}>
          {customButtonLabel || t('alert.done')}
        </Button>
      </Wrapper>
    </Modal>
  );
};
