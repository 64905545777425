import { ReactNode } from 'react';

import { Button } from 'components/Button/Button';
import { ReactComponent as ErrorIcon } from 'icons/errorIcon.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ButtonWrapper, ErrorBlockWrapper, IconWrapper, Message } from './ErrorBlock.styles';

export type ErrorBlockProps = {
  content?: ReactNode;
  actionButton?:
    | {
        onClick: () => void;
        customLabel?: ReactNode;
      }
    | {
        to: string;
        label: ReactNode;
      };
  handleButtonClick?: () => ReactNode;
  customButtonLabel?: ReactNode;
  withMargin?: boolean;
};

const ErrorBlock = ({ content, actionButton, withMargin }: ErrorBlockProps) => {
  const { t } = useTranslation();

  return (
    <ErrorBlockWrapper withMargin={withMargin}>
      <IconWrapper>
        <ErrorIcon />
      </IconWrapper>
      <Message>{content || t('errorBlock.defaultContent')}</Message>
      {actionButton && 'onClick' in actionButton && (
        <ButtonWrapper>
          <Button type="button" size="medium" variant="default" onClick={actionButton.onClick}>
            {actionButton.customLabel || t('errorBlock.defaultButtonLabel')}
          </Button>
        </ButtonWrapper>
      )}
      {actionButton && 'to' in actionButton && (
        <ButtonWrapper>
          <Button type="button" size="medium" variant="default" as={Link} to={actionButton.to}>
            {actionButton.label}
          </Button>
        </ButtonWrapper>
      )}
    </ErrorBlockWrapper>
  );
};

export default ErrorBlock;
