import styled from 'styled-components';

export const InfoBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

export const IconWrapper = styled.div`
  width: 88px;
  height: 88px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.gray140};
  border-radius: 50%;

  svg {
    width: 43px;
    height: 43px;

    path {
      fill: ${({ theme }) => theme.colors.green};
    }
  }
`;

export const Message = styled.span`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.colors.gray70};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 1.5;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

export const ButtonWrapper = styled.div``;
