export const LOCALSTORE_AUTH_TOKENS_KEY = 'mac_token_v1';
export const KEYCLOAK_CONFIG = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
  url: process.env.REACT_APP_KEYCLOAK_URL || '',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
  registerUrl:
    process.env.REACT_APP_KEYCLOAK_REGISTER_URL || 'https://aurea-preprod.mac.ci.1000i.pl/register',
};

type AuthTokens = {
  accessToken: string;
  refreshToken: string;
};

const isValidAuthTokensObject = (tokens: unknown): tokens is AuthTokens => {
  return (
    typeof tokens === 'object' &&
    tokens !== null &&
    'accessToken' in tokens &&
    'refreshToken' in tokens
  );
};

export const saveAuthTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(
    LOCALSTORE_AUTH_TOKENS_KEY,
    JSON.stringify({
      accessToken,
      refreshToken,
    }),
  );
};

export const removeAuthTokens = () => {
  localStorage.removeItem(LOCALSTORE_AUTH_TOKENS_KEY);
};

export const getAuthTokensFromStore = () => {
  try {
    const localstoreTokens = localStorage.getItem(LOCALSTORE_AUTH_TOKENS_KEY);

    if (!localstoreTokens) return null;

    const tokens = JSON.parse(localstoreTokens);

    if (!tokens || !isValidAuthTokensObject(tokens)) {
      return null;
    }

    return {
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
    };
  } catch (error) {
    return null;
  }
};
