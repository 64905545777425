import styled, { css } from 'styled-components';

export const ErrorBlockWrapper = styled.div<{ withMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  ${(props) =>
    props.withMargin &&
    css`
      margin: 25px 0;
    `}
`;

export const IconWrapper = styled.div`
  width: 88px;
  height: 88px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.gray140};
  border-radius: 50%;
`;

export const Message = styled.span`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.colors.gray70};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 1.5;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

export const ButtonWrapper = styled.div``;
