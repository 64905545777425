export const defaultTheme = {
  colors: {
    // all colors
    black: '#0D0D0D',
    blue: '#0054A6',
    navy: '#004899',
    navy120: '#82A6CD',
    navy130: '#BED0E5',
    navy140: '#D0DDEC',
    navy150: '#E4ECF4',
    navy160: '#EEF3F8',
    navy170: '#F6F9FC',
    orange: '#F39200',
    magenta: '#E6007E',
    green: '#95C11F',
    green140: '#F2F7E3',
    white: '#FFFFFF',
    gray70: '#2C2C2C',
    gray80: '#686868',
    gray90: '#ADADAD;',
    gray100: '#C6C6C6',
    gray110: '#a3a3a3',
    gray120: '#dfdfdf',
    gray130: '#EAEAEA',
    gray140: '#F8F8F8',
    transparent: '#ffffff00',
    overlay: 'rgba(128, 128, 128, 0.3)',
  },
  zIndex: {
    modal: 10,
    navigation: 1,
    background: -1,
  },
  boxShadows: {
    ultraLight: '0px 4px 4px rgba(0, 0, 0, 0.04), 0px -4px 10px rgba(0, 0, 0, 0.05)',
    light: '4px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.04)',
    lightGray: '0px 4px 4px rgba(0, 0, 0, 0.04), 0px -4px 10px rgba(0, 0, 0, 0.05);',
    dark: '0px 4px 10px rgba(0, 0, 0, 0.05), 0px 4px 15px rgba(0, 0, 0, 0.1)',
    largeDark:
      '0px 100px 210px rgba(0, 0, 0, 0.12), 0px 22px 46px rgba(0, 0, 0, 0.07), 0px 7px 14px rgba(0, 0, 0, 0.1)',
  },
  gradient: {
    green: '151.06deg, #BED974 -4.85%, #A2C93B 25.87%',
  },
  radius: {
    none: '0',
    xsmall: '2px',
    small: '5px',
    medium: '10px',
    large: '25px',
  },
  fontSizes: {
    xxsmall: '10px',
    xsmall: '11px',
    small: '13px',
    medium: '16px',
    large: '18px',
    xlarge: '24px',
    xxlarge: '36px',
  },
  fontWeight: {
    light: '300',
    regular: '400',
    semibold: '600',
    bold: '700',
    extrabold: '800',
  },
  breakpoints: {
    xs: 480,
    s: 768,
    m: 900,
    l: 1200,
    xl: 1600,
  },
  fontFamily: {
    primary: "'Lato', sans-serif",
  },
} as const;

export type ThemeType = typeof defaultTheme;
