import React, { ReactNode } from 'react';

import ErrorBlock from 'components/ErrorBlock/ErrorBlock';
import { Translation } from 'react-i18next';

type ErrorBoundaryProps = {
  children: ReactNode;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Translation>
          {(t) => (
            <ErrorBlock
              withMargin
              content={t('page.page500.content')}
              actionButton={{
                onClick: () => window.location.reload(),
                customLabel: t('page.page500.buttonLabel'),
              }}
            />
          )}
        </Translation>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
