import { useEffect, useState } from 'react';

import InfoBlock from 'components/InfoBlock/InfoBlock';
import Modal from 'components/Modal/Modal';
import { Trans, useTranslation } from 'react-i18next';
import * as serviceWorker from 'serviceWorkerRegistration';

type ServiceWorkerProviderProps = {
  children: React.ReactNode;
};

const ServiceWorkerProvider = ({ children }: ServiceWorkerProviderProps) => {
  const { t } = useTranslation();
  const [isServiceWorkerUpdateReady, setIsServiceWorkerUpdateReady] = useState(false);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: async (registration) => {
        if (registration && registration.waiting) {
          setIsServiceWorkerUpdateReady(true);
        }
      },
    });
  }, []);

  return (
    <>
      {children}
      <Modal isOpen={isServiceWorkerUpdateReady} handleClose={() => window.location.reload()}>
        <InfoBlock
          content={<Trans>{t('updateAlert.content')}</Trans>}
          actionButton={{
            onClick: () => {
              window.location.reload();
            },
            label: t('updateAlert.buttonLabel'),
          }}
        />
      </Modal>
    </>
  );
};

export default ServiceWorkerProvider;
