import { createGlobalStyle } from 'styled-components';

import { ThemeType } from '../themes/default/defaultTheme';

export const GlobalStyles = createGlobalStyle<{
  theme: ThemeType;
}>`
  html {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: ${(props) => props.theme.fontFamily.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  input, textarea, select, button {
    font-family: ${(props) => props.theme.fontFamily.primary}
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  * {
    margin: 0;
    padding: 0;
  }
`;
