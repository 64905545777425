/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from 'styled-components';

import { defaultTheme } from '../themes/default/defaultTheme';

export const respondTo = Object.entries(defaultTheme.breakpoints)
  .map(([breakpoint, value]) => {
    return {
      [breakpoint]: (params: any, ...other: any[]) => {
        return css`
          @media screen and (min-width: ${value}px) {
            ${css(params, ...other)}
          } ;
        `;
      },
    };
  })
  .reduce((accumulator, current) => ({ ...accumulator, ...current }));
