import { useEffect } from 'react';

import { Button } from 'components/Button/Button';
import { paths } from 'config/paths';
import { ReactComponent as LogoMacIcon } from 'icons/logoMacIcon.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import KeycloakManager from 'services/keycloak/keycloak.service';

import {
  ButtonPlayStore,
  ButtonsWrapper,
  ContentWrapper,
  HomeWrapper,
  ImageWrapper,
  LogoWrapper,
} from './Home.styles';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (KeycloakManager.getIsAuthenticated()) {
      navigate(paths.LOGIN_CALLBACK);
    }
  }, [navigate]);

  return (
    <HomeWrapper>
      <ImageWrapper />
      <ContentWrapper>
        <LogoWrapper>
          <LogoMacIcon />
        </LogoWrapper>
        <ButtonsWrapper>
          {t('page.home.hint')}
          <Button
            type="button"
            size="medium"
            variant="default"
            as="a"
            href={KeycloakManager.getLoginUrl()}
          >
            {t('page.home.actionLogin')}
          </Button>
          <Button
            type="button"
            size="medium"
            variant="light"
            as="a"
            href={KeycloakManager.getRegisterUrl()}
          >
            {t('page.home.actionRegister')}
          </Button>

          <ButtonPlayStore>
            <a href="https://play.google.com/store/apps/details?id=pl.mac.aplikacja_nauczyciela.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                style={{ height: '3.5rem' }}
                alt="pobierz z Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/pl_badge_web_generic.png"
              />
            </a>
          </ButtonPlayStore>
        </ButtonsWrapper>
      </ContentWrapper>
    </HomeWrapper>
  );
};

export default Home;
