import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { history } from 'config/history';
import App from 'containers/App/App';
import ErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary';
import ServiceWorkerProvider from 'containers/ServiceWorkerProvider/ServiceWorkerProvider';
import { GlobalAlertContextProvider } from 'context/globalAlert/globalAlert';
import ReactDOM from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import KeycloakManager from 'services/keycloak/keycloak.service';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from 'styles/global/global';
import { defaultTheme } from 'styles/themes/default/defaultTheme';

import 'lang/i18n';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

const renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        <ServiceWorkerProvider>
          <QueryClientProvider client={queryClient}>
            <HistoryRouter history={history}>
              <GlobalStyles />
              <ErrorBoundary>
                <GlobalAlertContextProvider>
                  <App />
                </GlobalAlertContextProvider>
              </ErrorBoundary>
            </HistoryRouter>
          </QueryClientProvider>
        </ServiceWorkerProvider>
      </ThemeProvider>
    </React.StrictMode>,
  );
};

KeycloakManager.init(renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
