import classroomImage from 'images/classroom.jpg';
import styled from 'styled-components';
import { respondTo } from 'styles/utils/respondTo';

export const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 400px;
  position: relative;

  ${respondTo.m`
    flex-direction: row;
  `}
`;

export const ImageWrapper = styled.div`
  height: auto;
  flex: 1;
  background-image: url(${classroomImage});
  background-size: cover;
  background-position: center;

  ${(props) => respondTo.m`
    width: 50%;
    box-shadow: ${props.theme.boxShadows.largeDark};
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 20px 40px;

  align-items: center;
  ${respondTo.m`
    width: 50%;
    justify-content: center;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-align: center;
  color: ${({ theme }) => theme.colors.gray80};

  width: 100%;
  max-width: 340px;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  top: 20px;
  width: 181px;
  height: 141px;
  ${respondTo.m`
    position: static;
    width: 309px;
    height: 241px;
  `}
`;

export const ButtonPlayStore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
