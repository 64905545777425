import { ReactNode } from 'react';

import { ReactComponent as CloseIcon } from 'icons/closeIcon.svg';
import ReactModal from 'react-modal';

import { CloseButton, CloseWrapper, ModalFooter, ModalStyle, OverlayStyle } from './Modal.styles';

ReactModal.setAppElement('#root');

export interface Position {
  x: string;
  y: string;
}

type ModalProps = {
  children: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  footer?: ReactNode;
  isCloseButtonHidden?: boolean;
  shouldReturnFocusAfterClose?: boolean;
  hasPadding?: boolean;
  position?: Position;
  limitWidth?: boolean;
  radius?: string;
};

const Modal = ({
  children,
  isOpen,
  handleClose,
  footer,
  isCloseButtonHidden,
  shouldReturnFocusAfterClose = true,
  hasPadding = true,
  position,
  limitWidth = false,
  radius,
}: ModalProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentElement={(props, children) => (
        <ModalStyle
          hasPadding={hasPadding}
          position={position}
          limitWidth={limitWidth}
          radius={radius}
          {...props}
        >
          {children}
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => (
        <OverlayStyle {...props}>{contentElement}</OverlayStyle>
      )}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
    >
      {!isCloseButtonHidden && (
        <CloseWrapper>
          <CloseButton type="button" onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </CloseWrapper>
      )}
      {children}
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </ReactModal>
  );
};

export default Modal;
